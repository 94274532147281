.card {
  width: 150px;
  height: 150px;
  cursor: pointer;
  /*
  background: #424856;
  border: 3px solid #b3f4f9;
  box-shadow: 4px 4px 10px rgba(221, 250, 252, 0.81);
  border-radius: 10px; */
  /* background: #363739;
  border-radius: 21%;
  box-sizing: border-box;

  box-shadow: inset 4.5px 4.5px 9px #232324, inset -4.5px -4.5px 9px #494b4e; */
  background: #2c2d2f;
  border-radius: 27%;
  box-shadow: inset 5.41px 5.41px 9px #161718, inset -5.41px -5.41px 9px #424447;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card__image {
  margin: 10px;
}

.card__image img {
  vertical-align: middle;
  width: 42px;
  height: 42px;
  margin: -1px 2px 1px;
}

.card__name {
  color: white;
}

.card__name h3 {
  margin: 0;
  text-align: center;
  font-weight: 400;
}

.card__name_code {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80px;
  margin: 0;
  font-weight: 100;
}

.card__name__copy {
  padding: 3px;
  cursor: pointer;
  background-color: #6f747e;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.card__name__copy:hover {
  background-color: #97999c;
}

.copy__icon {
  padding: 2px;
  height: 15px !important;
}

@media screen and (max-width: 600px) {
  .card {
    padding: 6%;
    width: 120px;
    height: 80px;
  }
  .card__name__copy {
    width: max-content;
  }
  .card__name {
    max-width: 120px;
  }
  .card__name h3 {
    font-size: 20px;
  }
}

@media screen and (width: 320px) {
  .card {
    padding: 6%;
    width: 80px;
    height: 60px;
  }
}
