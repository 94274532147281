.App {
  display: flex;
  background-color: #2c2d2f;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* height: 100vh; */
  overflow-y: scroll;
  min-width: 100vh;
  min-height: 100vh;
}

.link{
  margin-left: 5px;
  color: #ff093c
}

.app__screen__about {
  width: 80%;
  padding: 2%;
  /* border-radius: 62px;
  background: #343537;
  box-shadow: 11px 11px 22px #1d1d1e, -11px -11px 22px #4b4d50; */
  -webkit-border-radius: 49px;
  border-radius: 49px;

  background: linear-gradient(
    145deg,
    #38393c,
    #222324
  ); /* -webkit-box-shadow: 8px 8px 8px #28282a, -8px -8px 8px #404244;
  /* box-shadow: 8px 8px 8px #28282a, -8px -8px 8px #404244; */
  box-shadow: 5.71px 5.71px 4px #1e1f20, -5.71px -5.71px 4px #4c4d50;

  text-align: center;
}
.app__screen__about:focus {
  outline: none;
  border-color: #9ecaed;
  box-shadow: 0 0 10px #9ecaed;
}

.main__about__title h3 {
  color: #fbfcfe;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}
/* .sidebar {
  flex: 0.3;
  background: blue;
}*/
.main__about__content p {
  text-align: justify;
  color: #fbfcfe;
}

.app__emotees__section__title {
  color: #fbfcfe;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}
.emotees__display {
  display: grid;

  grid-template-columns: repeat(5, 1fr);
  padding: 40px;
}
.app__emotees__section {
  width: 80%;
  padding: 2%;
  /* border-radius: 62px;
  background: #343537;
  box-shadow: 11px 11px 22px #1d1d1e, -11px -11px 22px #4b4d50; */
  -webkit-border-radius: 49px;
  border-radius: 49px;

  background: linear-gradient(145deg, #343537, #28292b);
  /* -webkit-box-shadow: 8px 8px 8px #28282a, -8px -8px 8px #404244;
  /* box-shadow: 8px 8px 8px #28282a, -8px -8px 8px #404244; */
  box-shadow: 5.71px 5.71px 4px #1e1f20, -5.71px -5.71px 4px #4c4d50;

  margin-top: 5%;
  text-align: center;
}
.app__emotees__tabs {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
}
.app__emotees__tabs__single {
  background: linear-gradient(145deg, #434547, #29292b);
  border-radius: 9%;
  padding: 5px;
  width: 15vw;
  cursor: pointer;
  color: #e6e7ee;
  font-weight: 500;

  box-shadow: 3.9px 3.9px 4px #1a1b1c, -3.9px -3.9px 4px #424346;
}

.app__emotees__tabs__single:hover {
  /* background: linear-gradient(145deg, #76787a, #2b2b2e); */
  letter-spacing: 3px;
}

.contact {
  text-align: center;
  margin: 5%;
  width: 80%;
  padding: 2%;
  border-radius: 40px;
  background: #2b2b2c;
  box-shadow: 4.8px 4.8px 8px #19191a, -4.8px -4.8px 8px #4a4b4e;
}

 .contact p {
    align-items: center;
    justify-content: center;
    color: #ff093c;
    text-align: center;
    margin-top: 5%;
  }
.contact__icons {
  display: flex;
  padding-top: 3%;
  gap: 50px;
  justify-content: center;
  color: #ff093c;
}
.ml15{
  margin-left: 15px
}
.insta:hover {
  color: #8a3ab9;
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}
.ext{
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
  
}
.ext:hover {
  color: #1872ea;
  
}
.link:hover {
  color: #0074b1;
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}
.github:hover {
  color: white;
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}
.fb:hover {
  color: #1872ea;
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}
.twitter:hover {
  color: #1da1fd;
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

/* 
@media only screen and (width: 320px) {
  .app__screen {
    padding-top: 85%;
    margin-top: 100%;
    background-color: darkcyan;
  }
  .app__screen__about {
    margin-top: 35%;
    margin-bottom: 0%;
  }
}

@media only screen and (max-width: 1200px) {
  .app__screen {
    padding-top: 45%;

    margin-top: 50%;
  }
  .app__screen__about {
    margin-top: 45%;
    margin-bottom: 2%;
  }
} */

@-webkit-keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@keyframes action {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}
@media only screen and (max-width: 300px) {
  .app__screen {
    background-color: skyblue;
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .app__screen {
    width: 100%;
    margin-bottom: 40%;
  }
  .app__screen__about {
    padding: 6%;
    height: max-content;
  }
  .main__about__content {
    align-content: center;
  }
  .main__about__title {
    font-size: 45px;
  }
  .main__about__content p {
    align-items: center;
    justify-content: center;
    font-size: 40px;
    color: rgb(173, 171, 171);

    text-align: center;
  }
  .app__emotees__section {
    background-color: peru;
    padding: 6%;
  }
  .app__emotees__section__title {
    color: whitesmoke;

    font-size: 40px;
  }
  .app__emotees__tabs__single {
    width: max-content;
  }
  .app__emotees__tabs__single {
    color: rgb(173, 171, 171);
    font-size: 28px;
  }
  .contact {
    padding: 6%;
  }
  .contact h2 {
    font-size: 50px;
    font-weight: 600;
    padding: 20px;
    color: white;
  }
  .contact p {
    align-items: center;
    justify-content: center;
    color: whitesmoke;
    font-size: 35px;
    text-align: center;
    margin-top: 5%;
  }
  .contact__icons > .MuiSvgIcon-root {
    font-size: 50px;
  }
}

@media only screen and (max-width: 414px) and (min-width: 370px) {
  .app__screen {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .app__screen {
    width: 100%;
  }
  .app__screen__about {
    padding: 5%;
    height: fit-content;
  }
  .main__about__content {
    align-content: center;
  }
  .main__about__title h3 {
    font-size: 35px;
  }
  .main__about__content p {
    color: rgb(173, 171, 171);

    text-align: justify;
    font-size: 30px;
  }
  .app__emotees__section {
    padding: 6%;
  }
  .app__emotees__section__title {
    color: whitesmoke;

    font-size: 40px;
  }
  .app__emotees__tabs__single {
    width: max-content;
  }
  .app__emotees__tabs__single {
    color: rgb(173, 171, 171);
    font-size: 28px;
  }
  .contact {
    padding: 3%;
    height: fit-content;
  }
  .contact h2 {
    font-size: 50px;
    font-weight: 600;
    padding: 20px;
    color: white;
  }
  .contact p {
    align-items: center;
    justify-content: center;
    color: whitesmoke;
    text-align: justify;

    font-size: 30px;
    text-align: center;
    margin-top: 5%;
  }
  .contact__icons > .MuiSvgIcon-root {
    font-size: 50px;
  }
}
